import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {FlexCol, P, Span} from '@quarks';
import {AvatarStack, ModalCell, ModalCellButton, ModalCellItem, ModalCellItemIcon} from '@molecules';
import {WorkspaceSelectionCard} from '@organisms';
import {
  getInitialSelectedWorkspace,
  getConnectedUsersByDate,
  getAreaById,
  getSelectedSectionTitle,
  getSelectedSectionSubtitle,
  getNodeInformationByNodeIdInEditMode,
  getIsLastNodeSelected,
  getIsSelectedBuildingWithoutFloors,
  getIsLoadingDeskSuggestions,
  getSelectedBuildingName,
  getSelectedNodes,
  getNodeTypeAndId,
  selectLocationBasedOnBuildingId,
  selectFloor,
  selectArea,
  selectDesks,
  getIsLastSelectedWorkspaceReservationLocationLoaded,
} from '@lib/store';
import {pxToRem} from '@utils';
import {useTheme} from 'styled-components';
import {parseISO} from 'date-fns';
import {StyledText} from '../BookWorkspaceCard/styles';
import {Icon} from '@atoms';

export function SelectWorkspaceButton({date, inviteeCount}: {date: string; inviteeCount: number}) {
  const {addPages} = useModal();
  const theme = useTheme();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {buildingId, floorId, areaId, deskIds} = useAppSelector(getSelectedNodes);
  const lastSelectedWorkspaceLoaded = useAppSelector(getIsLastSelectedWorkspaceReservationLocationLoaded);
  const initialSelectedWorkspace = useAppSelector((state) =>
    getInitialSelectedWorkspace(state, {
      selectedDate: date,
      buildingId,
    }),
  );

  const {
    buildingId: initialBuildingId,
    floorId: initialFloorId,
    areaId: initialAreaId,
    deskIds: initialDeskIds = [],
  } = initialSelectedWorkspace ?? {};

  const {
    nodeIds: [selectedNodeId],
    nodeType,
  } = getNodeTypeAndId(buildingId, floorId, areaId, deskIds);

  useEffect(
    function selectInitial() {
      if (initialSelectedWorkspace && lastSelectedWorkspaceLoaded) {
        if (!buildingId) {
          dispatch(selectLocationBasedOnBuildingId(initialBuildingId ?? ''));

          if (initialFloorId) {
            dispatch(selectFloor(initialFloorId ?? ''));
            dispatch(selectArea(initialAreaId ?? ''));
            dispatch(selectDesks(initialDeskIds ?? []));
          }
        }

        if (buildingId && buildingId === initialBuildingId && !floorId && initialFloorId) {
          dispatch(selectFloor(initialFloorId ?? ''));
          dispatch(selectArea(initialAreaId ?? ''));
          dispatch(selectDesks(initialDeskIds ?? []));
        }
      }
    },
    [
      buildingId,
      areaId,
      dispatch,
      initialAreaId,
      initialBuildingId,
      initialDeskIds,
      initialFloorId,
      initialSelectedWorkspace,
      lastSelectedWorkspaceLoaded,
      floorId,
    ],
  );

  const nodeId = areaId ?? floorId ?? buildingId;
  const selectedArea = useAppSelector((state) => getAreaById(state, selectedNodeId));

  // TODO: This selector should handle buildings that support direct bookings without floors
  const defaultTitle = useAppSelector((state) => getSelectedSectionTitle(state, date));
  const fallbackTitle = useAppSelector(getSelectedBuildingName);
  const selectedNodeTitle = defaultTitle || fallbackTitle;

  const {hasInsufficientSpaceForGroupBooking} = useAppSelector((state) =>
    getNodeInformationByNodeIdInEditMode(state, {
      date: parseISO(date),
      nodeId: areaId ?? floorId ?? buildingId,
      inviteeCount,
    }),
  );
  const selectedNodeSubTitle = useAppSelector((state) => {
    if (hasInsufficientSpaceForGroupBooking) {
      return t('workplace:NotEnoughAvailableDesks');
    }

    const deskSubTitle = t('workplace:AnyAvailableDesk', {count: inviteeCount + 1});

    // TODO: this logic should handle building level bookings (now returns void)
    return getSelectedSectionSubtitle(state, deskSubTitle) || deskSubTitle;
  });

  const hasBookableSelection = useAppSelector(getIsLastNodeSelected);

  const selectedBuildingIsWithoutFloors = useAppSelector((state) => getIsSelectedBuildingWithoutFloors(state));

  // TODO: add to selector
  const isDisabled = useAppSelector(getIsLoadingDeskSuggestions);
  const locationCanBeChanged = !selectedBuildingIsWithoutFloors;

  const connections = useAppSelector((state) =>
    getConnectedUsersByDate(state, {
      date,
      nodeId,
      buildingId,
    }),
  );

  if (!hasBookableSelection) {
    return (
      <ModalCellButton
        data-testid="select-workspace-button"
        onClick={() =>
          addPages([
            <WorkspaceSelectionCard
              date={date}
              inviteeCount={inviteeCount}
            />,
          ])
        }>
        <P color={theme.input['placeholder']}>{t('workplace:SelectWorkspace')}</P>
      </ModalCellButton>
    );
  }

  return (
    <ModalCell>
      <ModalCellItem
        alignItems="flex-start"
        data-testid="select-workspace-button"
        disabled={isDisabled || !locationCanBeChanged}
        left={
          <ModalCellItemIcon
            icon={'desk'}
            disabled={isDisabled}
          />
        }
        middle={
          <FlexCol
            gap={8}
            paddingTop={8}>
            <StyledText
              $disabled={isDisabled}
              fontWeight="bold">
              {selectedNodeTitle}
            </StyledText>
            <StyledText $disabled={isDisabled}>{selectedNodeSubTitle}</StyledText>
            <AvatarStack
              date={date}
              disabled={isDisabled}
              users={connections}
              tooltipId="SelectWorkspaceButton"
              overlap
            />
          </FlexCol>
        }
        right={
          locationCanBeChanged && (
            <Span padding={4}>
              <Icon
                size={pxToRem(24)}
                icon="arrowRight"
                aria-label={t('workplace:CheckRoomInfo')}
              />
            </Span>
          )
        }
        onClick={() =>
          !isDisabled &&
          locationCanBeChanged &&
          addPages([
            <WorkspaceSelectionCard
              date={date}
              inviteeCount={inviteeCount}
            />,
          ])
        }
      />
    </ModalCell>
  );
}
