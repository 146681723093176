import {
  useAppDispatch,
  useAppSelector,
  useBreakPoint,
  useIsPageActive,
  useModal,
  useRefreshDataIfStale,
  useRoomImage,
  useScheduleAndSensorStatuses,
} from '@hooks';
import {
  withAsyncThunkErrorHandling,
  getUser,
  getIsWorkdayLoading,
  MeetingRoom,
  loadAllMeetingRooms,
  getAllMeetingRoomSuggestions,
  getMeetingRoomSuggestionByBuildingIdLoadingStatus,
} from '@lib/store';
import {AvailableRoomInformationModal, AvailableRoomsSkeletonLoader, HaNScheduleDataIcon, Tile} from '@organisms';
import addMinutes from 'date-fns/addMinutes';
import startOfMinute from 'date-fns/startOfMinute';
import {ReactNode, useEffect} from 'react';
import {DEFAULT_NEW_EVENT_DURATION, REFRESH_INTERVAL_SECONDS} from '@constants';
import {useTranslation} from 'react-i18next';
import {cn} from '@utils';
import {StyledSwiperHorizontal} from '../../hereAndNow/HaNRoomListHorizontal/styles';
import {useNavigate} from 'react-router-dom';
import {trackEvent} from '@lib/infrastructure';

export const AvailableRoomsTile = ({time, buildingId}: {time: Date; buildingId: string}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const breakPoint = useBreakPoint();
  const pageActive = useIsPageActive();
  const {id: loggedInUserId} = useAppSelector(getUser);

  const workdayLoading = useAppSelector(getIsWorkdayLoading);

  const allRooms = useAppSelector((state) => getAllMeetingRoomSuggestions(state, buildingId ?? ''));
  const meetingRoomsAreLoading = useAppSelector(
    (state) => getMeetingRoomSuggestionByBuildingIdLoadingStatus(state, buildingId ?? '') === 'loading',
  );
  const firstThreeRooms = allRooms.slice(0, 3);
  const {refreshIfStale, refreshLabel} = useRefreshDataIfStale(meetingRoomsAreLoading, REFRESH_INTERVAL_SECONDS);
  const isLoading = workdayLoading || meetingRoomsAreLoading;
  const newStartDateTime = startOfMinute(time);
  const newEndDateTime = addMinutes(newStartDateTime, DEFAULT_NEW_EVENT_DURATION);

  useEffect(
    function loadAvailableRooms() {
      if (!pageActive) return;

      if (!buildingId) return;

      refreshIfStale(() => {
        dispatch(
          withAsyncThunkErrorHandling(() =>
            loadAllMeetingRooms({
              startDateTime: newStartDateTime.toISOString(),
              endDateTime: newEndDateTime.toISOString(),
              buildingId: buildingId,
              internalAttendeeIds: [loggedInUserId],
              totalAttendees: 1,
            }),
          ),
        );
      });
    },
    [buildingId, dispatch, loggedInUserId, newEndDateTime, newStartDateTime, pageActive, refreshIfStale, time],
  );

  const RowContainer =
    breakPoint === 'small'
      ? ({children}: {children: ReactNode[]}) => (
          <StyledSwiperHorizontal spaceBetween={16}>{children}</StyledSwiperHorizontal>
        )
      : ({children}: {children: ReactNode[]}) => <div className="grid grid-cols-6 w-full gap-8">{children}</div>;

  return (
    <Tile
      title={t('FindAMeetingRoom')}
      viewAllAction={() => {
        trackEvent('Home_AvailableRoomsTile__ViewAll');
        navigate('/now/rooms');
      }}
      titleNote={refreshLabel}>
      {isLoading ? (
        <AvailableRoomsSkeletonLoader columns={3} />
      ) : firstThreeRooms.length ? (
        <RowContainer>
          {buildingId
            ? firstThreeRooms.map((room) => (
                <AvailableRoomTile
                  key={room.id}
                  room={room}
                  startTime={newStartDateTime.toISOString()}
                  endTime={newEndDateTime.toISOString()}
                  buildingId={buildingId}
                  className={firstThreeRooms.length === 3 ? 'col-span-2' : 'col-span-3'}
                />
              ))
            : null}
          {firstThreeRooms.length === 1 ? (
            <div className="bg-collaborative-blue-50 rounded-md flex flex-col justify-center items-center w-full aspect-[255/150] h-44 col-span-3">
              <p>{t('NoOtherRoomsAvailable')}</p>
            </div>
          ) : null}
        </RowContainer>
      ) : (
        <div className="bg-collaborative-blue-50 w-full rounded-md flex flex-col justify-center items-center h-64">
          <p>{t('NoRoomsAvailable')}</p>
        </div>
      )}
    </Tile>
  );
};

const AvailableRoomTile = ({
  room,
  startTime,
  endTime,
  buildingId,
  className,
}: {
  room: MeetingRoom;
  startTime: string;
  endTime: string;
  buildingId: string;
  className: string;
}) => {
  const {t} = useTranslation();
  const bp = useBreakPoint();
  const {openModal, addPages} = useModal();
  const {floorName, displayName, capacity, email, roomPictureHash} = room;
  const {scheduleStatus, sensorStatus} = useScheduleAndSensorStatuses(room, startTime, endTime);
  const imageURL = useRoomImage(
    email || displayName,
    roomPictureHash,
    buildingId,
    Math.round(48 * window.devicePixelRatio * 4),
  );

  function handleRoomClick(selectedRoom: MeetingRoom, startDateTime: string, endDateTime: string) {
    addPages([
      <AvailableRoomInformationModal
        room={selectedRoom}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
      />,
    ]);

    trackEvent('Home_AvailableRoomsTile__ViewDetails', {roomName: selectedRoom.displayName});
    openModal();
  }

  return (
    <button
      data-testid="HomePage-available-room"
      className={cn('flex flex-col gap-4 rounded-md outline-offset-8 outline-gray-400', className)}
      onClick={() => handleRoomClick(room, startTime, endTime)}>
      <img
        alt={room.displayName}
        src={imageURL}
        className={cn(`cursor-pointer object-cover rounded-md w-full aspect-[255/150] h-[176px]`)}
      />
      <div
        className={cn('flex flex-row justify-between gap-2 items-center', {
          'flex-col-reverse items-start': bp === 'medium',
        })}>
        <div className="flex flex-col">
          <h4>{displayName}</h4>
          <p>
            {t('meeting:MeetingRoomBasicInfo', {
              count: capacity,
              floorName,
            })}
          </p>
        </div>
        <div className="flex flex-row gap-2">
          {sensorStatus && (
            <HaNScheduleDataIcon
              data-testid={`HomePageSensorIcon-${floorName}`}
              status={sensorStatus}
              icon="broadcast"
            />
          )}
          {scheduleStatus && (
            <HaNScheduleDataIcon
              data-testid={`HomePageScheduleDataIcon-${floorName}`}
              status={scheduleStatus}
              icon="calendar"
            />
          )}
        </div>
      </div>
    </button>
  );
};
